@media screen and (max-width: 799px) {
    .main-panel .content {
      padding: 10px 6px;
      /* min-height: calc(100vh - 123px); */
    }
    div.content-fluid .row .col-md-12 {
      padding: 4px;
    }
    div.content-fluid .row .col-md-12 .card .card-header {
      padding: 0%;
    }
    div.content-fluid .row .col-md-12 .card .card-body {
      padding: 4px;
    }
    div.content-fluid .row .col-md-12 .card .card-body .select-account {
      margin: 0%;
    }
    div.content-fluid .row .col-md-12 .card .card-body .main-actions {
      margin: 0%;
    }
  
    div.content-fluid .row .col-md-12 .card .card-body #trans-grid-notification {
      font-size: small;
      margin-left: 6px;
      margin-bottom: 12px;
    }
  
    .footer:not(.footer-big) nav>ul {
      display: none;
    }
    /* .footer:not(.footer-big) nav>ul>li {
      margin-left: 2px;
    }
    .footer:not(.footer-big) nav>ul a {
      padding: 2px 0px;
      margin: 3px 3px 3px 0px;
    } */
  
    .footer:not(.footer-big) nav>p {
      font-size: smaller;
      margin: 0%;
      padding: 0%;
    }
    .footer:not(.footer-big) nav>p.attributes {
      text-align: center;
    }
  
  
  }