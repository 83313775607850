.btn-danger {
    background-color: #ff0000a6;
}

.accounts-paper {
    height: calc(100vh - 170px);
}
/* 
.content {
    background-color: #9368e9;
} */