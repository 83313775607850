#accountinfo-members-grid {
    height: 60vh;
    margin-top: 6px;
    padding: 1vw;
}

#account-info-actions {
    margin-left: 2vw
}

.archived-account-title {
    font-size: larger;
}