
/**
 * CARD layout design
 * Ref: https://dev.to/prvnbist/css-grid-cards-layout-aspect-ratio-45ni
 */

/*
1. 1:1 - 100%
2. 16:9 - 56.25%
3. 4:3 - 75%
4. 3:2 - 66.66%
5. 8:5 - 62.5%
*/
  /* body {
    padding: 16px;
    background: #19191c;
  } */
  
@media screen and (max-width: 419px) {


    .account-filter-icon.active {
        border: 2px solid blue;
        /* padding: 1px; */
    }

    ul.cardGrid {
        margin: 2px;
        padding: 8px;
        display: grid;
        grid-gap: 0 px;
        padding-inline-start: 0px;
        /*
        Minimum Width = 368px
        Maximum Width = 1fr i.e full width
        A card will wrap to next row if the browser can not fit cards with their minimum width
        
        auto-fill - this will try to fill all the columns or leave an empty columns if there aren't enough cards
        */
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }

    .owner-account-card {
        border: 6px solid #103f6d;
        /* padding: 2px; */
    }
    /* .owner-account-card > li:hover {
        border: 6px solid #103f6d;
        padding: 2px;
    } */

    .shared-account-card {
        border: 6px solid brown;
        padding: 2px;
    }
    /* .shared-account-card > li:hover {
        border: 6px solid brown;
        padding: 2px;
    } */

    ul.cardGrid/*  a  */ li {
        background: rgb(2,0,36);
        background: linear-gradient(330deg, rgba(2,0,36,0.8911939775910365) 0%, rgba(87,161,149,1) 52%, rgba(0,212,255,1) 100%);
        padding-top: 56.25%;
        border-radius: 10px;
        transition: transform 1s; /* Animation */
        overflow: hidden;
        position: relative;
        min-height: '100px';
    }

    /* ul.cardGrid li:hover {
        padding: 2px;
        border-radius: 5px;
        z-index: 4;
        min-height: '100px';
        transform: scale(1.2);
    } */
    
  .archive-account {
    opacity: 0.75;
   }
  }