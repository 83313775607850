.sidebar .footer {
  margin-left: 10px ;
  margin-top: 40px;
  font-size: x-small;
  background-color: transparent;
  color: rgb(32, 58, 27);
}

@media screen and (max-width: 26em) {
  .sidebar .footer {
    margin-left: 10px ;
    margin-top: 40px;
    font-size: medium;
    font-weight: bolder;
    background-color: transparent;
    color: rgb(8, 32, 3);
  }
}


#sidebar-logo-link {
  text-transform: uppercase;
  padding: 5px 0px;
  display: inline-block;
  font-size: 1.0rem;
  font-weight: 400;
  line-height: 30px;
  white-space: nowrap;
  color: #FFFFFF;
  overflow: hidden;
}