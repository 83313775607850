.listitems-paper {
    height: calc(100vh - 160px);
    width: calc(100vw - 260px - 4vw);
    margin: 1vw;

}

@media screen and (max-width: 420px) {
    .listitems-paper {
        height: 78vh;
        width: 94vw;
        margin-top: 2vh;
        margin-left: 3vw;
        padding: 2vw;
    }
}

.tran-credit-row {
    color: rgb(0, 4, 238) !important;
}

.tran-debit-row {
    color: rgb(255, 6, 6) !important;
}

.tran-both-row {
    color: rgb(46, 202, 56) !important;
}

.list-item-active {
    /* light-sky-blue background */
    /* background: rgb(33 150 243 / 65%) !important; */
}

.list-item-inactive {
    /* light-red background */
    background: rgb(251 64 75 / 71%) !important;
}