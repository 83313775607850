.Upload {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    text-align: left;
    overflow: hidden;
  }
  
  .Content {
    display: flex;
    flex-direction: row;
    padding-top: 16px;
    box-sizing: border-box;
    width: 100%;
  }
  
  .Files {
    margin-left: 32px;
    align-items: flex-start;
    justify-items: flex-start;
    flex: 1;
    overflow-y: auto;
    margin-bottom: 8px;
    font-size: 16px;
    color: #555;
  }
  
  .Actions {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 32px;
  }
  
  .Title {
    margin-bottom: 32px;
    color: #555;
  }
    
  .Row {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    height: 50px;
    padding: 8px;
    overflow: hidden;
    box-sizing: border-box;
}

button {
  font-family: 'Roboto medium', sans-serif;
  font-size: 14px;
  display: inline-block;
  height: 36px;
  min-width: 88px;
  padding: 6px 16px;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  border-radius: 2px;
  background: rgba(103, 58, 183, 1);
  color: #fff;
  outline: 0;
}

button:disabled {
  background: rgb(189, 189, 189);
  cursor: default;
}

.ofx-credit-row {
    /* background-color: rgb(182, 229, 182) !important; */
    color: rgb(0, 4, 238) !important;
}

.ofx-debit-row {
  /* background-color: rgb(182, 229, 182) !important; */
  color: rgb(255, 6, 6) !important;
}


.ofx-dup-row-4 {
  /* background-color: rgb(182, 229, 182) !important; */
  color: rgb(255, 255, 255) !important;
  background-color: lightcoral !important;
}
.ofx-dup-row-3 {
  /* background-color: rgb(182, 229, 182) !important; */
  color: rgb(14, 13, 13) !important;
  background-color: rgb(240, 195, 128) !important;
}

.ofx-dup-row-2 {
  /* background-color: rgb(182, 229, 182) !important; */
  color: rgb(14, 13, 13) !important;
  background-color: rgb(211, 201, 115) !important;
}
.ofx-dup-row-1 {
  /* background-color: rgb(182, 229, 182) !important; */
  color: rgb(0, 0, 0) !important;
  background-color: rgb(232, 240, 128) !important;
}

.custom-tooltip {
  width: 1500px;
  height: 30px;
  border: 1px solid cornflowerblue;
  overflow: hidden;
}

/* .tooltip {
  position: relative;
  opacity: 0;
  padding: 10px;
  background: #9B59B6;
  border-radius: 5px;
  -webkit-transition:all 0.2s ease-in;
  -moz-transition:all 0.2s ease-in;
  transition:all 0.2s ease-in;
} */

.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}