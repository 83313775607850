
#trans-account-select {
  height: 58px;
}

#trans-account-select.trans-account-select-width {
  width: 'auto';
  min-width:'60vw'
}

#search-trans-account-select {
  height: 58px;
}

#search-trans-account-select.search-trans-account-select-width {
  width: 'auto';
  min-width:'60vw'
}
@media screen and (max-width: 420px) {
    
  #trans-account-select {
    height: 58px;
  }

  #trans-account-select.trans-account-select-width {
    width: 'auto';
    min-width:'60vw'
  }

  #search-trans-account-select {
    height: 58px;
  }

  #search-trans-account-select.trans-account-select-width {
    width: 'auto';
    min-width:'60vw'
  }

}