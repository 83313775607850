
.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 560px;
  padding: 40px;
}

.each-slide span {
  padding: 20px;
  padding-left: 100px;
  font-size: 30px;
  margin-top: 260px;
  /* background: #efefef; */
  text-align: center;
}

.react-slideshow-container .default-nav {
    height: 46px;
    border-radius: 0%;
    opacity: 0.3;
}
