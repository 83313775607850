.btn-danger {
    background-color: #ff0000a6;
}

.tran-credit-row {
    /* background-color: rgb(182, 229, 182) !important; */
    color: rgb(0, 4, 238) !important;
}

.tran-debit-row {
  /* background-color: rgb(182, 229, 182) !important; */
  color: rgb(255, 6, 6) !important;
}

.tran-link-iq {
  background-color: rgb(154, 194, 240) !important;
}

.tran-link-oq {
  background-color: rgb(241, 161, 168) !important;
}
/* 
.tran-link-na {
  background-color: rgb(233, 219, 160) !important;
} */

#trans-paper {
  height: 90vh;
  width: 96vw;
  margin-top: 2vh;
  margin-left: 1vw;
  margin-bottom: 2vh;
}

#trans-tran-grid {
  height: 64vh;
  margin-top: 8px;
  padding: 0.2vw;
}

#trans-grid-notification {
  height: 3vh;
  margin-top: 2px;
  margin-left: 1vw;
}

#trans-actions {
    /* margin-top: 26px; */
    /* margin-left: 10px; */
}

#trans-actions-left > *{
  /* margin-left: 24px; */
}

.dropdown-item {
  font-size: larger;
}
.dropdown-item i {
  font-size: x-large;
}

@media screen and (max-width: 420px) {
  #trans-paper {
    height: 140vh;
    width: 96vw;
    margin-top: 2vh;
    margin-left: 1vw;
  }

  #trans-tran-grid {
    height: 90vh;
    margin-top: 6px;
    padding: 1vw;
  }

  #trans-grid-notification {
    height: 6vh;
    margin-top: 2px;
    margin-left: 1vw;
  }

  #trans-actions {
    /* margin-top: 26px; */
    /* margin-left: 10px; */
  }
  #trans-actions-left > *{
    /* margin-left: 24px; */
  }
  
  .dropdown-item {
    font-size: large;
  }
  .dropdown-item i {
    font-size: larger;
    margin-top: -2px;
  }

}

.button-link li a {
  color: black !important;
}
.button-link li a:hover, .button-link li a:focus {
  color: red;
}

#dropdownFilterButton {
  width: 30px;
  border: 0px;
  color: black;
  background-color: beige;
  padding: 0px;
}

#dropdownActionsButton {
  width: 30px;
  border: 0px;
  color: black;
  background-color: beige;
  padding: 0px;
}

.pe-icon.selected {
  color: blue;
}

.transactions-paper {
  height: calc(100vh - 230px);
  /* width: calc(100vw - 260px - 4vw); */
  margin: 1vw;

}

@media screen and (max-width: 420px) {
  .transactions-paper {
      height: 78vh;
      width: 94vw;
      margin-top: 2vh;
      margin-left: 3vw;
      padding: 2vw;
  }
}