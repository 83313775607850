/* custom top margin for auth screens. */
.auth-form-container {
  padding-top: 4em;
}

/* <hr /> only added for card-register; apply for all auth screens */
.form .card.card-register hr {
  border-top: 1px solid rgba(0,0,0,.1);
}

/* ------------------------------------------------------------------------ */
/* Shadow effect for forgot form */
.card.card-forgot {
  /* margin-top: 4em; */
  box-shadow: 0 25px 30px -13px rgba(40, 40, 40, 0.4);
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-transform: translate3d(0, 0px, 0);
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}

.card.card-forgot .form-check {
  margin-left: -12px;
}

.card.card-forgot.card-hidden {
  opacity: 0;
  -webkit-transform: translate3d(0, -60px, 0);
  -moz-transform: translate3d(0, -60px, 0);
  -o-transform: translate3d(0, -60px, 0);
  -ms-transform: translate3d(0, -60px, 0);
  transform: translate3d(0, -60px, 0);
}
/* ------------------------------------------------------------------------ */



/* ------------------------------------------------------------------------ */
/* Shadow effect for register form */
.card.card-register {
  /* margin-top: 3em; */
  box-shadow: 0 25px 30px -13px rgba(40, 40, 40, 0.4);
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-transform: translate3d(0, 0px, 0);
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}

.card.card-register .form-check {
  margin-left: -12px;
}

.card.card-register.card-hidden {
  opacity: 0;
  -webkit-transform: translate3d(0, -60px, 0);
  -moz-transform: translate3d(0, -60px, 0);
  -o-transform: translate3d(0, -60px, 0);
  -ms-transform: translate3d(0, -60px, 0);
  transform: translate3d(0, -60px, 0);
}
/* ------------------------------------------------------------------------ */


/* ------------------------------------------------------------------------ */
/* Shadow effect for reset form */
.card.card-reset {
  /* margin-top: 2em; */
  box-shadow: 0 25px 30px -13px rgba(40, 40, 40, 0.4);
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-transform: translate3d(0, 0px, 0);
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}

.card.card-reset .form-check {
  margin-left: -12px;
}

.card.card-reset.card-hidden {
  opacity: 0;
  -webkit-transform: translate3d(0, -60px, 0);
  -moz-transform: translate3d(0, -60px, 0);
  -o-transform: translate3d(0, -60px, 0);
  -ms-transform: translate3d(0, -60px, 0);
  transform: translate3d(0, -60px, 0);
}
/* ------------------------------------------------------------------------ */
